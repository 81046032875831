import React, { useEffect } from 'react';

const ShowCategories = ({ setSelectedCategory }) => {
  useEffect(() => {
    setSelectedCategory(null);
  });

  return '';
};

export default React.memo(ShowCategories);

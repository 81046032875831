import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import toCurrency from 'alisto.js/lib/to-currency';
import ClickOnceButton from '../ClickOnceButton';
import i18n from '../../../../javascript/js/i18n';

const PaymentForm = function ({
  missingPayment, onCancel, onSubmit, paymentMethods,
}) {
  const [method, setMethod] = useState('');
  const [total, setTotal] = useState(missingPayment);
  const [needsChange, setNeedsChange] = useState(false);
  const [changeFor, setChangeFor] = useState('');

  const submittable = method && total && (method !== 'cash' || !needsChange || changeFor);

  const setPayment = (e) => {
    const { value } = e.target;
    setMethod(value);
  };

  const pay = () => {
    const option = document.querySelector(`#payment_method option[value='${method}']`);
    onSubmit({
      provider: option.dataset.provider,
      method,
      total,
      needs_change: needsChange,
      label: option.innerHTML,
      change_for: changeFor,
      payment_provider_id: option.dataset.providerId,
      payment_method_id: option.dataset.methodId,
    });
  };

  return (
    <>
      <DialogContent dividers>
        <FormControl variant="outlined" fullWidth className="pb-3">
          <InputLabel>Método</InputLabel>
          <Select
            native
            required
            id="payment_method"
            value={method}
            onChange={setPayment}
            label="Método"
          >
            <option aria-label={i18n.chooseOne} value="" />
            {paymentMethods.delivery.map((paymentMethod) => (
              <option
                key={paymentMethod.sku}
                value={paymentMethod.sku}
                data-provider="delivery"
                data-provider-id={paymentMethod.providerId}
                data-method-id={paymentMethod.methodId}
              >
                {paymentMethod.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" fullWidth className="pb-2">
          <InputLabel htmlFor="payment_total">Valor</InputLabel>
          <OutlinedInput
            id="payment_total"
            value={total}
            onChange={(e) => setTotal(e.target.value)}
            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
            label="Valor"
          />
        </FormControl>
        {method === 'cash' && (
          <>
            <FormControlLabel
              htmlFor="payment_needs_change"
              control={(
                <Checkbox
                  id="payment_needs_change"
                  checked={needsChange}
                  onChange={(e) => setNeedsChange(e.target.checked)}
                  color="primary"
                />
              )}
              className="pb-2"
              label={i18n.needsChange}
            />
            {needsChange && (
              <>
                <FormControl variant="outlined" fullWidth className="pb-3">
                  <InputLabel htmlFor="payment_change_for">{i18n.changeFor}</InputLabel>
                  <OutlinedInput
                    id="payment_change_for"
                    value={changeFor}
                    onChange={(e) => setChangeFor(parseFloat(e.target.value))}
                    startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                    label={i18n.changeFor}
                  />
                </FormControl>
                {changeFor && `${i18n.change}: ${toCurrency(changeFor - total)}`}
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          Cancelar
        </Button>
        <ClickOnceButton
          color="primary"
          onClick={pay}
          disabled={!submittable}
        >
          {i18n.addPayment}
        </ClickOnceButton>
      </DialogActions>
    </>
  );
};

export default PaymentForm;

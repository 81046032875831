import theme from './theme';

const container = document.querySelector('[data-palette]');
const palette = JSON.parse(container.dataset.palette);

theme.palette.primary.main = palette.primaryColor;
theme.palette.primary.dark = palette.darkPrimaryColor;
theme.palette.secondary.main = palette.secondaryColor;

export default theme;

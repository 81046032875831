import action from 'alisto.js/lib/action';
import calculateFreightAndDispatch from '../delivery/freight';

export default (order, deliveryMethod, slug, dispatch, useFreightPrice) => {
  if (deliveryMethod === 'delivery') {
    if (useFreightPrice) {
      dispatch(action('CONFIRM_GOOD_ADDRESS', { ...order, price: order.freightPrice }));
    } else if (order.latitude && order.longitude) {
      calculateFreightAndDispatch(slug, order, dispatch);
    } else {
      dispatch(action('LOAD_ADDRESS', order));
    }
  }
  if (order.items) {
    order.items.forEach((item) => {
      dispatch(action('ADD_ITEM', item));
    });
  }
};

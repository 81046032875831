import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faAt,
  faBell,
  faBurn,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faCrosshairs,
  faDollarSign,
  faInfoCircle,
  faStore,
  faMotorcycle,
  faClock,
  faMapMarkerAlt,
  faPhone,
  faPlusCircle,
  faReceipt,
  faSearch,
  faSignOutAlt,
  faPen,
  faTags,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import { faApple, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

library.add(
  faApple,
  faArrowLeft,
  faAt,
  faBell,
  faBurn,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faCrosshairs,
  faDollarSign,
  faInfoCircle,
  faFacebook,
  faStore,
  faMotorcycle,
  faClock,
  faMapMarkerAlt,
  faPhone,
  faPlusCircle,
  faReceipt,
  faSearch,
  faSignOutAlt,
  faPen,
  faTags,
  faUser,
  faWhatsapp,
);

dom.watch();

import { getToken } from 'firebase/messaging';

const VAPID_KEY = 'BPKKLrImJIQPl7PVHjuWhY_xHYk5hsCrBcUe-ubRPh36xHIzfbCS8W9rtMtwxjfpX9zIMUO3mplk2T6U23qKiAs';

export default (path) => {
  if ('serviceWorker' in navigator) {
    return import('./firebase-messaging').then((messaging) => getToken(messaging.default, { vapidKey: VAPID_KEY }).then((token) => {
      if (token) {
        return fetch(path, {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          },
          body: JSON.stringify({ token }),
        });
      }
      return new Promise(() => {});
    }));
  }
  return new Promise(() => {});
};

import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import locale from 'alisto.js/lib/locale';
import lang from 'alisto.js/lib/lang';
import placeToAddress from '../lib/place-to-address';
import i18n from '../../../javascript/js/i18n';

const GooglePlacesInput = function ({
  address,
  onSearch,
  onSelect,
  loadingLabel,
  fullScreen,
  latitude,
  longitude,
  radius,
}) {
  const handleSelect = (selectedAddress) => {
    geocodeByAddress(selectedAddress).then((places) => {
      onSelect(placeToAddress(places[0]));
    }).catch(() => {});
  };

  const origin = (latitude && longitude) ? { lat: latitude, lng: longitude } : null;

  const locationBias = (latitude && longitude) ? {
    center: { lat: latitude, lng: longitude },
    radius,
  } : null;

  const searchOptions = {
    types: ['address'],
    fields: [
      'address_components.short_name',
      'address_components.types',
      'geometry.location',
    ],
    componentRestrictions: {
      country: [locale],
    },
    language: lang,
    region: locale,
    origin,
    locationBias,
  };

  const style = { width: (fullScreen ? '100%' : '500px') };

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      value={address}
      onChange={onSearch}
      onSelect={handleSelect}
      onError={() => {}}
    >
      {({
        getInputProps, suggestions, getSuggestionItemProps, loading,
      }) => (
        <div className="position-relative" style={style}>
          <input
            {...getInputProps({
              placeholder: i18n.searchAddress,
              className: 'form-control autocomplete-address',
            })}
          />
          <div className="position-fixed pt-1" style={style}>
            {loading ? (
              <div className="p-2 bg-light">
                {loadingLabel}
                ...
              </div>
            ) : suggestions.map((suggestion) => {
              const className = `p-2 autocomplete-suggestion ${suggestion.active ? 'bg-primary text-white' : 'bg-light'}`;
              return (
                <div
                  {...getSuggestionItemProps(suggestion, { className })}
                  style={{ cursor: 'pointer' }}
                  key={suggestion.placeId}
                >
                  <i className="fas fa-map-marker-alt mx-2" />
                  <span className="mr-2">{suggestion.formattedSuggestion.mainText}</span>
                  <small>{suggestion.formattedSuggestion.secondaryText}</small>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesInput;

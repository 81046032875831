import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

const ClickOnceButton = function ({
  color, onClick, disabled, children,
}) {
  const [enabled, setEnabled] = useState(true);

  const click = (e) => {
    setEnabled(false);
    onClick(e);
  };

  return (
    <Button
      color={color}
      onClick={click}
      disabled={!enabled || disabled}
    >
      {children}
    </Button>
  );
};

export default React.memo(ClickOnceButton);

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import action from 'alisto.js/lib/action';

const ShowProduct = ({ dispatch, products }) => {
  const params = useParams();

  useEffect(() => {
    const product = products.find((item) => item.slug === params.slug);
    if (product) {
      dispatch(action('CLICK_PRODUCT', product));
    }
  });

  return '';
};

export default React.memo(ShowProduct);

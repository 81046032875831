import React, { useState, useEffect } from 'react';
import cep from 'cep-promise';
import LocationPicker from 'react-location-picker';
import { geocodeByAddress } from 'react-places-autocomplete';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import addressToString from '../lib/address-to-string';
import placeToAddress from '../lib/place-to-address';
import Address from './Address';
import NeighborhoodSelect from './NeighborhoodSelect';
import i18n from '../../../javascript/js/i18n';

const AddressConfirmation = function ({
  onConfirm,
  address,
  title,
  streetLabel,
  numberLabel,
  complementLabel,
  referenceLabel,
  continueLabel,
  showMap,
  freightType,
  enableZipcodeInput,
}) {
  const [currentAddress, setCurrentAddress] = useState(address);
  const mergeCurrentAddress = (addressToMerge) => setCurrentAddress({ ...currentAddress, ...addressToMerge });

  const setStreet = (street) => mergeCurrentAddress({ street });
  const setNumber = (number) => mergeCurrentAddress({ number });
  const setComplement = (complement) => mergeCurrentAddress({ complement });
  const setNeighborhood = (neighborhood) => mergeCurrentAddress({ neighborhood });
  const setReference = (reference) => mergeCurrentAddress({ reference });

  const setPoint = (location) => {
    const place = location.places[0];
    const newAddress = placeToAddress(place);
    mergeCurrentAddress({ latitude: newAddress.latitude, longitude: newAddress.longitude });
  };

  const setLocation = (location) => mergeCurrentAddress(placeToAddress(location.places[0]));

  const submit = (event) => {
    event.preventDefault();
    onConfirm(currentAddress);
  };

  const fetchStreet = () => {
    cep(currentAddress.zipcode).then((json) => setStreet(json.street)).catch(() => {});
  };

  const isMapVisible = showMap && currentAddress.latitude && currentAddress.longitude;

  const geocode = () => {
    if (isMapVisible) {
      geocodeByAddress(addressToString(currentAddress)).then((places) => {
        mergeCurrentAddress(placeToAddress(places[0]));
      }).catch(() => {});
    }
  };

  useEffect(() => {
    if (enableZipcodeInput && currentAddress.street === null
      && currentAddress.zipcode !== null) {
      fetchStreet();
    }
  });

  return (
    <>
      <h4 className="pb-2">{title}</h4>
      {isMapVisible && currentAddress.geolocated && (
        <div className="pb-3">
          <small>{i18n.moveMapMarker}</small>
          <LocationPicker
            containerElement={<div />}
            mapElement={<div style={{ height: '200px' }} />}
            defaultPosition={{
              lat: parseFloat(currentAddress.latitude),
              lng: parseFloat(currentAddress.longitude),
            }}
            zoom={17}
            radius={-1}
            onChange={setLocation}
          />
        </div>
      )}
      <div className="pb-2">
        <Address
          neighborhood={currentAddress.neighborhood}
          city={currentAddress.city}
          state={currentAddress.state}
          zipcode={currentAddress.zipcode}
          street={isMapVisible ? currentAddress.street : null}
        />
      </div>
      <form onSubmit={submit} className="mt-3">
        <div className="row">
          {!isMapVisible && (
            <div className="col-12 mb-3">
              <TextField
                label={streetLabel}
                id="address_street"
                value={currentAddress.street || ''}
                type="text"
                required
                variant="outlined"
                fullWidth
                onChange={(e) => setStreet(e.target.value)}
              />
            </div>
          )}
          <div className="col-5 mb-3">
            <TextField
              label={numberLabel}
              id="address_number"
              value={currentAddress.number || ''}
              type="text"
              required
              inputMode="numeric"
              pattern="[0-9]*"
              variant="outlined"
              fullWidth
              onChange={(e) => setNumber(e.target.value)}
              onBlur={currentAddress.geolocated ? null : geocode}
            />
          </div>
          <div className="col-7 mb-3">
            <TextField
              label={complementLabel}
              id="address_complement"
              value={currentAddress.complement || ''}
              type="text"
              variant="outlined"
              fullWidth
              onChange={(e) => setComplement(e.target.value)}
            />
          </div>
          <div className="col-12 mb-3">
            {freightType === 'by_neighborhood' ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{i18n.neighborhood}</InputLabel>
                <NeighborhoodSelect
                  value={currentAddress.neighborhood}
                  onChange={setNeighborhood}
                  id="address_neighborhood"
                  includeBlank
                />
              </FormControl>
            ) : (
              <TextField
                label={i18n.neighborhood}
                id="address_neighborhood"
                value={currentAddress.neighborhood || ''}
                type="text"
                required
                variant="outlined"
                fullWidth
                onChange={(e) => setNeighborhood(e.target.value)}
              />
            )}
          </div>
          <div className="col-12 mb-3">
            <TextField
              label={referenceLabel}
              id="address_reference"
              value={currentAddress.reference || ''}
              type="text"
              variant="outlined"
              multiline
              fullWidth
              onChange={(e) => setReference(e.target.value)}
            />
          </div>
        </div>
        {isMapVisible && !currentAddress.geolocated && (
          <div className="pb-3">
            <small>{i18n.moveMapMarker}</small>
            <LocationPicker
              containerElement={<div />}
              mapElement={<div style={{ height: '200px' }} />}
              defaultPosition={{
                lat: parseFloat(currentAddress.latitude),
                lng: parseFloat(currentAddress.longitude),
              }}
              zoom={17}
              radius={-1}
              onChange={setPoint}
            />
          </div>
        )}
        <input
          className="btn w-100 btn-primary btn-raised"
          type="submit"
          value={continueLabel}
        />
      </form>
    </>
  );
};

export default AddressConfirmation;

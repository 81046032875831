import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

export default (color) => withStyles({
  root: {
    backgroundColor: color.main,
    color: color.contrastText,
    '&:hover': { backgroundColor: color.dark },
    '&:disabled': { backgroundColor: color.light },
  },
})(Button);

import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import router from '../../../../javascript/js/router';

const CategoryButton = function ({ category, categoryAvailable }) {
  const opacity = categoryAvailable ? 1 : 0.5;
  return (
    <Link
      to={router.categoryPath(category)}
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
      }}
    >
      <Card style={{ opacity, width: '100%' }}>
        <CardActionArea>
          <CardMedia
            image={category.coverImageUrl ?? `https://via.placeholder.com/400/100?text=${category.name}`}
            title={category.name}
            style={{ height: '100px' }}
            loading="lazy"
          />
          <CardContent>
            <Typography>
              {category.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default React.memo(CategoryButton);

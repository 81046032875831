import { createTheme } from '@material-ui/core/styles';
import primary from '@material-ui/core/colors/teal';
import secondary from '@material-ui/core/colors/lightBlue';
import success from '@material-ui/core/colors/green';

success.contrastText = '#fff';
secondary.contrastText = '#fff';

export default createTheme({
  palette: { primary, secondary, success },
  typography: {
    fontFamily: [
      'Menu Integrado',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
  },
});

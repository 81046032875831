const html = document.querySelector('html');
const pad = (number) => number.toString().padStart(2, '0');

const newDate = () => new Date(
  new Date().toLocaleString(
    'en',
    { timeZone: html.dataset.timezone },
  ),
);

const validShift = (start, stop) => {
  const date = newDate();
  const current = `${pad(date.getHours())}:${pad(date.getMinutes())}`;
  return !!(start && stop && start <= current && current <= stop);
};

const validHour = (category) => {
  const {
    start1, stop1, start2, stop2,
  } = category;
  return validShift(start1, stop1) || validShift(start2, stop2);
};

export default (category) => {
  const day = newDate().getDay();
  return category.forceAlwaysVisible || (category.days[day] && validHour(category));
};

import toItemsHash from './order/to-items-hash';
import populate from './populate';
import populateAddress from './order/populate-address';

export default (store) => {
  const state = store.getState();
  const address = state.confirmedAddress;
  populate('order_items_hash', toItemsHash(state.items));
  populate('order_donation', state.donation);
  populate('order_user_name', state.userName);
  populate('order_user_mobile_phone', state.userMobilePhone);
  populateAddress(address);
};

import './lib/delivery/fonts';
import 'core-js/features/string/replace-all';

import('./lib/delivery/shopping-cart');

window.showSchedule = () => {
  import('../../javascript/js/material').then(() => {
    if (window.openModal) {
      window.openModal('schedule-modal');
    }
  });
};

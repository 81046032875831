import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import AddressConfirmationModalContent from './AddressConfirmationModalContent';
import AddressInputModalContent from './AddressInputModalContent';

const Transition = React.forwardRef((props, ref) => <Grow ref={ref} {...props} />);

const AddressForm = function ({
  googleMapsKey,
  canInputFreightPrice,
  enableDelivery,
  enablePickup,
  servesLocal,
  address,
  isOutOfRange,
  show,
  onHide,
  onSelectAddress,
  onSearchAddress,
  onResetAddress,
  onWrongAddress,
  addresses,
  searchedAddress,
  onConfirmedAddress,
  question,
  outOfRange,
  loading,
  confirmationTitle,
  numberLabel,
  complementLabel,
  referenceLabel,
  cityLabel,
  neighborhoodLabel,
  continueLabel,
  backLabel,
  zipcodeLabel,
  streetLabel,
  freightPriceLabel,
  freightPriceHint,
  freightType,
  deliveryLabel,
  pickupLabel,
  searchLabel,
  useCurrentPositionLabel,
  currency,
  zipcodeMask,
  city,
  country,
  deliveryMethod,
  onChangeDeliveryMethod,
  enableCurrentPosition,
  enableAutocompleteAddressInput,
  enableZipcodeInput,
  showMap,
  searchButton,
  isSignedIn,
  minWait,
  maxWait,
  open,
  latitude,
  longitude,
  radius,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={show}
      onClose={onHide}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      style={{ zIndex: 2147483643 }}
    >
      {address === null || canInputFreightPrice ? (
        <AddressInputModalContent
          googleMapsKey={googleMapsKey}
          addresses={addresses}
          outOfRange={outOfRange}
          question={question}
          onHide={onHide}
          searchedAddress={searchedAddress}
          onConfirmedAddress={onConfirmedAddress}
          onSearchAddress={onSearchAddress}
          onSelectAddress={onSelectAddress}
          onWrongAddress={onWrongAddress}
          loading={loading}
          canInputFreightPrice={canInputFreightPrice}
          useCurrentPositionLabel={useCurrentPositionLabel}
          zipcodeLabel={zipcodeLabel}
          streetLabel={streetLabel}
          numberLabel={numberLabel}
          complementLabel={complementLabel}
          referenceLabel={referenceLabel}
          cityLabel={cityLabel}
          neighborhoodLabel={neighborhoodLabel}
          freightPriceLabel={freightPriceLabel}
          freightPriceHint={freightPriceHint}
          freightType={freightType}
          confirmLabel={continueLabel}
          deliveryLabel={deliveryLabel}
          pickupLabel={pickupLabel}
          searchLabel={searchLabel}
          currency={currency}
          zipcodeMask={zipcodeMask}
          city={city}
          country={country}
          isOutOfRange={isOutOfRange}
          enableDelivery={enableDelivery}
          enablePickup={enablePickup}
          servesLocal={servesLocal}
          deliveryMethod={deliveryMethod}
          onChangeDeliveryMethod={onChangeDeliveryMethod}
          enableCurrentPosition={enableCurrentPosition}
          enableAutocompleteAddressInput={enableAutocompleteAddressInput}
          enableZipcodeInput={enableZipcodeInput}
          address={address || {}}
          searchButton={searchButton}
          fullScreen={fullScreen}
          isSignedIn={isSignedIn}
          minWait={minWait}
          maxWait={maxWait}
          open={open}
          latitude={latitude}
          longitude={longitude}
          radius={radius}
        />
      ) : (
        <AddressConfirmationModalContent
          onResetAddress={onResetAddress}
          backLabel={backLabel}
          onHide={onHide}
          onConfirmedAddress={onConfirmedAddress}
          address={address}
          confirmationTitle={confirmationTitle}
          streetLabel={streetLabel}
          numberLabel={numberLabel}
          complementLabel={complementLabel}
          referenceLabel={referenceLabel}
          continueLabel={continueLabel}
          showMap={showMap}
          freightType={freightType}
          enableZipcodeInput={enableZipcodeInput}
        />
      )}
    </Dialog>
  );
};

export default AddressForm;

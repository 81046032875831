import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import truncate from '../../../../javascript/js/truncate';
import i18n from '../../../../javascript/js/i18n';
import router from '../../../../javascript/js/router';

const Product = function ({
  product,
  categoryAvailable,
  menu,
  width = '100%',
  direction = 'row',
  productOrder = 1,
  coverMarginLeft = 'auto',
  coverWidth = '110px',
  coverHeight = '110px',
  coverMarginBottom = 'auto',
}) {
  const navigate = useNavigate();

  const onClick = () => {
    if (menu.available || menu.readonly) {
      product.categoryAvailable = categoryAvailable;
      navigate(router.productPath(product));
    } else {
      window.showSchedule();
    }
  };

  const opacity = (product.inStock && categoryAvailable) ? 1 : 0.5;

  return (
    <Card
      onClick={onClick}
      style={{ opacity, width, height: '100%' }}
      id={`item_${product.id}`}
      data-id={product.id}
      className="item"
    >
      <CardActionArea style={{ height: '100%' }}>
        <CardContent style={{ display: 'flex', height: '100%', flexDirection: direction }}>
          <div className="d-flex flex-column pr-2" style={{ order: 1 }}>
            <strong className="mb-1 name" style={{ color: 'black' }}>
              {product.name}
              {menu.mostOrderedProductId === product.id && (
                <small className="alert alert-warning-default p-2 ml-2 rounded">
                  <i className="fa fa-burn mr-1" />
                  {i18n.theMostOrdered}
                </small>
              )}
            </strong>
            <div className="description mb-1" style={{ color: 'black' }}>
              {product.description ? truncate(product.description, 140) : ''}
            </div>
            <div className="price mt-auto">
              <Typography color="secondary" display="inline">{product.textPrice}</Typography>
              {product.originalPrice
                && parseFloat(product.originalPrice) > parseFloat(product.price) && (
                  <>
                    <del className="text-muted ml-1">
                      {product.textOriginalPrice}
                    </del>
                    <small
                      className="text-primary ml-2"
                      style={{ verticalAlign: 'text-top' }}
                    >
                      <strong>
                        -
                        {(((product.originalPrice - product.price) * 100) / product.originalPrice).toFixed()}
                        %
                      </strong>
                    </small>
                  </>
              )}
              {!product.inStock && <Chip label={i18n.outOfStock} className="ml-2" />}
              {!categoryAvailable && <Chip label={i18n.unavailable} className="ml-2" />}
              {product.daysToPrepare !== null && product.daysToPrepare > 0 && (
                <Chip
                  label={`${product.daysToPrepare} ${i18n.daysToPrepare}`}
                  className="ml-2"
                />
              )}
            </div>
          </div>
          {product.coverImageUrl && (
            <div className="mr-0" style={{ order: productOrder, marginLeft: coverMarginLeft }}>
              <img
                src={product.coverImageUrl}
                loading="lazy"
                alt={product.name}
                style={{ width: coverWidth, height: coverHeight, marginBottom: coverMarginBottom }}
                className="rounded-lg"
              />
            </div>
          )}
          <Typography color="primary">
            <i className="fa fa-plus-circle position-absolute d-sm-none" style={{ right: '5px', bottom: '6px', fontSize: '1.2rem' }} />
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(Product);

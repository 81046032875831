import React from 'react';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

const PhoneInput = function ({
  label,
  id,
  name,
  value,
  mask,
  onChange,
  onBlur = () => {},
  required = false,
  className = '',
}) {
  return (
    <InputMask
      value={value}
      mask={mask.replaceAll('0', '9')}
      maskChar={null}
      onBlur={(e) => onBlur(e.target.value)}
      onChange={(e) => onChange(e.target.value)}
    >
      {() => (
        <TextField
          label={label}
          id={id}
          name={name}
          fullWidth
          required={required}
          type="tel"
          variant="outlined"
          className={className}
          inputProps={{
            pattern: (required ? `.{${mask.length - 1},${mask.length}}` : null),
            title: (required ? mask : null),
          }}
        />
      )}
    </InputMask>
  );
};

export default PhoneInput;

import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const AdultsGate = function ({
  question, minorAge, yesLabel, noLabel, goBack,
}) {
  const [isAdult, setIsAdult] = useState(null);
  const open = isAdult === null || !isAdult;

  return (
    <Dialog
      open={open}
      style={{ zIndex: 2147483643 }}
    >
      <DialogContent>
        {(isAdult === null || isAdult) ? (
          <>
            <div className="lead mb-4">
              {question}
            </div>
            <div className="text-center">
              <Button
                variant="outlined"
                color="primary"
                className="mx-2"
                size="large"
                onClick={() => setIsAdult(false)}
              >
                {noLabel}
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                className="mx-2"
                size="large"
                onClick={() => setIsAdult(true)}
              >
                {yesLabel}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="lead mb-4">
              {minorAge}
            </div>
            <div className="text-center">
              <Button
                variant="outlined"
                color="primary"
                className="mx-2"
                size="large"
                onClick={() => setIsAdult(null)}
              >
                {goBack}
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AdultsGate;

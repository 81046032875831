import geolocator from 'geolocator';

export default (key, callback) => {
  geolocator.config({
    language: document.querySelector('html').lang,
    google: { version: '3', key },
    raw: true,
  });
  const options = {
    enableHighAccuracy: true,
    fallbackToIP: false,
    addressLookup: true,
  };
  geolocator.locate(options, callback);
};

import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import i18n from '../../../../javascript/js/i18n';
import isFullScreen from '../../../../javascript/js/is-full-screen';
import PaymentForm from './PaymentForm';

const Payment = function ({
  missingPayment,
  onPayment,
  paymentMethods,
  fullWidth = false,
  className = '',
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (body) => {
    const response = onPayment(body);
    if (response) {
      response.then(handleClose);
    } else {
      handleClose();
    }
  };

  return (
    <>
      {missingPayment > 0 && (
        <Button
          variant="contained"
          className={`mb-3 add-payment ${className}`}
          onClick={handleOpen}
          fullWidth={fullWidth}
        >
          {i18n.addPayment}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={isFullScreen('sm')}
        scroll="paper"
        style={{ zIndex: 2147483643 }}
      >
        <DialogTitle>{i18n.addPayment}</DialogTitle>
        <PaymentForm
          missingPayment={missingPayment}
          onCancel={handleClose}
          onSubmit={onSubmit}
          paymentMethods={paymentMethods}
        />
      </Dialog>
    </>
  );
};

export default Payment;

import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import ZipcodeInput from './ZipcodeInput';
import NeighborhoodSelect from './NeighborhoodSelect';

const StartAddressInput = function ({
  country,
  enableZipcodeInput,
  submitZipcode,
  submitNeighborhood,
  searchLabel,
  zipcodeMask,
  searchButton,
  freightType,
}) {
  const [neighborhood, setNeighborhood] = useState('');
  const [zipcode, setZipcode] = useState('');

  if (freightType === 'by_neighborhood' && !enableZipcodeInput) {
    return (
      <form onSubmit={(event) => submitNeighborhood(event, neighborhood)}>
        <small className="text-center text-muted d-block pt-3 pb-1">
          ou escolha o bairro
        </small>
        <FormControl
          variant="outlined"
          fullWidth
          className="mb-2"
        >
          <NeighborhoodSelect
            onChange={setNeighborhood}
            id="neighborhood_input"
            includeBlank
          />
        </FormControl>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          fullWidth
          type="submit"
        >
          Continuar
        </Button>
      </form>
    );
  } if (country === 'BR' && enableZipcodeInput) {
    return (
      <form onSubmit={(event) => submitZipcode(event, zipcode)}>
        <div className="pb-2 text-center">
          <small className="text-muted d-block pt-3 pb-2">{searchLabel}</small>
          <ZipcodeInput id="address-zipcode" mask={zipcodeMask} onChange={setZipcode} />
        </div>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          fullWidth
          type="submit"
        >
          {searchButton}
        </Button>
      </form>
    );
  }
  return '';
};

export default StartAddressInput;

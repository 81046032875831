import populate from '../populate';

const value = (address, column) => (address ? (address[column] || null) : '');

export default (address) => {
  populate('order_street', value(address, 'street'));
  populate('order_number', value(address, 'number'));
  populate('order_complement', value(address, 'complement'));
  populate('order_neighborhood', value(address, 'neighborhood'));
  populate('order_city', value(address, 'city'));
  populate('order_state', value(address, 'state'));
  populate('order_country', value(address, 'country'));
  populate('order_reference', value(address, 'reference'));
  populate('order_zipcode', value(address, 'zipcode'));
  populate('order_latitude', value(address, 'latitude'));
  populate('order_longitude', value(address, 'longitude'));
  populate('order_geolocated', value(address, 'geolocated') || false);
  populate('order_freight_price', value(address, 'price'));
};

import { configureStore } from '@reduxjs/toolkit';
import persistState from 'redux-localstorage';
import reducer, { initialState } from 'alisto.js/lib/mi/reducers/order';

const container = document.querySelector('[data-slug]');
const key = container ? `${container.dataset.slug}-v2` : null;
const enhancers = [persistState(null, { key })];

export default configureStore({ reducer, enhancers, preloadedState: initialState });

export { key };

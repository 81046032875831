import { initializeApp } from 'firebase/app';

export default initializeApp({
  apiKey: 'AIzaSyBjoxAcDis2XREPfsW24uE_blXCXG02y8w',
  authDomain: 'menu-integrado.firebaseapp.com',
  databaseURL: 'https://menu-integrado.firebaseio.com',
  projectId: 'menu-integrado',
  storageBucket: 'menu-integrado.appspot.com',
  messagingSenderId: '76292932763',
  appId: '1:76292932763:web:215cdee1cb95fd6ec0323e',
  measurementId: 'G-133F7DV6VW',
});

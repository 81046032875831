import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddressConfirmation from './AddressConfirmation';

const AddressConfirmationModalContent = function ({
  onResetAddress,
  backLabel,
  onHide,
  onConfirmedAddress,
  address,
  confirmationTitle,
  numberLabel,
  referenceLabel,
  streetLabel,
  complementLabel,
  continueLabel,
  showMap,
  freightType,
  enableZipcodeInput,
}) {
  return (
    <>
      <DialogTitle>
        <Button onClick={onResetAddress} color="primary" className="text-nowrap">
          <i className="fa fa-arrow-left mr-1" />
          {backLabel}
        </Button>
        <IconButton
          aria-label="close"
          className="text-secondary float-right p-0 m-0"
          onClick={onHide}
          style={{ top: '5px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AddressConfirmation
          onConfirm={onConfirmedAddress}
          address={address}
          title={confirmationTitle}
          streetLabel={streetLabel}
          numberLabel={numberLabel}
          complementLabel={complementLabel}
          referenceLabel={referenceLabel}
          continueLabel={continueLabel}
          showMap={showMap}
          freightType={freightType}
          enableZipcodeInput={enableZipcodeInput}
        />
      </DialogContent>
    </>
  );
};

export default AddressConfirmationModalContent;

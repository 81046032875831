export default (place) => {
  const extract = (type) => {
    const componentEqualsType = (component) => component.types[0] === type;
    const component = place.address_components.find(componentEqualsType);
    return component !== undefined ? component.short_name : null;
  };

  const coord = (attr) => (typeof (attr) === 'function' ? attr() : attr);

  const { lat, lng } = place.geometry.location;

  return {
    street: extract('route'),
    number: extract('street_number'),
    neighborhood: extract('political'),
    zipcode: extract('postal_code'),
    city: extract('administrative_area_level_2'),
    state: extract('administrative_area_level_1'),
    country: extract('country'),
    latitude: coord(lat),
    longitude: coord(lng),
  };
};

import { getMessaging, onMessage } from 'firebase/messaging';
import firebase from './firebase-app';

const messaging = getMessaging(firebase);

onMessage(messaging, (payload) => {
  const { title, body, icon } = payload.notification;
  new Notification(title, { body, icon });
});

export default messaging;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ShowCategory = ({ categories, setSelectedCategory }) => {
  const params = useParams();

  useEffect(() => {
    const selectedCategory = categories.find((item) => item.slug === params.slug);
    setSelectedCategory(selectedCategory);
  });

  return '';
};

export default React.memo(ShowCategory);

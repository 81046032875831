import React from 'react';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

const ZipcodeInput = function ({
  id,
  mask,
  onChange,
}) {
  const change = (event) => onChange(event.target.value);

  return (
    <InputMask mask={mask} onChange={change}>
      {() => (
        <TextField
          id={id}
          fullWidth
          required
          type="tel"
          placeholder={mask.replaceAll('9', '0')}
          variant="outlined"
          inputProps={{ style: { textAlign: 'center ' } }}
        />
      )}
    </InputMask>
  );
};

export default ZipcodeInput;

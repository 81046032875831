import React from 'react';

const Address = function ({
  street, number, complement, neighborhood, city, state, zipcode,
}) {
  return (
    <>
      {(street || number || complement) && (
        <span className="mr-2">
          {street}
          {' '}
          {number}
          {' '}
          {complement}
        </span>
      )}
      <span className="text-muted d-block d-lg-inline">
        {neighborhood}
        {' '}
        {city}
        {' '}
        -
        {' '}
        {state}
      </span>
      <span className="d-block text-muted">{zipcode}</span>
    </>
  );
};

export default Address;

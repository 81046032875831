import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputMask from 'react-input-mask';
import cep from 'cep-promise';
import theme from '../lib/theme';

const InformAddress = function ({
  zipcodeLabel,
  streetLabel,
  numberLabel,
  complementLabel,
  referenceLabel,
  cityLabel,
  neighborhoodLabel,
  freightPriceLabel,
  freightPriceHint,
  confirmLabel,
  currency,
  zipcodeMask,
  onInformAddress,
  country,
  address,
}) {
  const [zipcode, setZipcode] = useState(address.zipcode || '');
  const [street, setStreet] = useState(address.street || '');
  const [number, setNumber] = useState(address.number || '');
  const [complement, setComplement] = useState(address.complement || '');
  const [neighborhood, setNeighborhood] = useState(address.neighborhood || '');
  const [city, setCity] = useState(address.city || '');
  const [state, setState] = useState(address.state || '');
  const [reference, setReference] = useState(address.reference || '');
  const [freightPrice, setFreightPrice] = useState(address.price || '');

  const fetchAndSetZipcode = (input) => {
    const newZipcode = input.value;
    setZipcode(newZipcode);
    if (newZipcode.replace(/\D/g, '').length === 8) {
      cep(newZipcode).then((json) => {
        setStreet(json.street);
        setNeighborhood(json.neighborhood);
        setCity(json.city);
        setState(json.state);
      });
    }
  };

  const submit = (event) => {
    event.preventDefault();
    onInformAddress({
      zipcode,
      street,
      number,
      complement,
      neighborhood,
      reference,
      city,
      state,
      country,
      price: freightPrice,
    }, true);
  };

  return (
    <ThemeProvider theme={theme}>
      <form id="inform-address-form" onSubmit={submit}>
        <div className="row">
          <div className="col-sm-3 my-2">
            <InputMask
              mask={zipcodeMask}
              disabled={false}
              value={zipcode}
              onChange={(e) => { fetchAndSetZipcode(e.target); }}
            >
              {() => <TextField label={zipcodeLabel} id="address-zipcode" fullWidth />}
            </InputMask>
          </div>
          <div className="col-sm-6 my-2">
            <TextField
              label={streetLabel}
              fullWidth
              id="address-street"
              value={street}
              onChange={(e) => { setStreet(e.target.value); }}
            />
          </div>
          <div className="col-sm-3 my-2">
            <TextField
              label={numberLabel}
              fullWidth
              id="address-number"
              value={number}
              onChange={(e) => { setNumber(e.target.value); }}
            />
          </div>
          <div className="col-sm-4 my-2">
            <TextField
              label={complementLabel}
              fullWidth
              id="address-complement"
              value={complement}
              onChange={(e) => { setComplement(e.target.value); }}
            />
          </div>
          <div className="col-sm-4 my-2">
            <TextField
              label={neighborhoodLabel}
              fullWidth
              id="address-neighborhood"
              value={neighborhood}
              onChange={(e) => { setNeighborhood(e.target.value); }}
              required
            />
          </div>
          <div className="col-sm-4 my-2">
            <TextField
              label={cityLabel}
              fullWidth
              id="address-city"
              value={city}
              onChange={(e) => { setCity(e.target.value); }}
            />
          </div>
          <div className="col-sm-6 my-2">
            <TextField
              label={referenceLabel}
              fullWidth
              id="address-reference"
              value={reference}
              onChange={(e) => { setReference(e.target.value); }}
            />
          </div>
          <div className="col-sm-6 my-2">
            <TextField
              label={freightPriceLabel}
              fullWidth
              id="address-freight-price"
              onChange={(e) => { setFreightPrice(e.target.value); }}
              value={freightPrice}
              helperText={freightPriceHint}
              InputProps={{ startAdornment: <InputAdornment position="start">{currency}</InputAdornment> }}
            />
          </div>
        </div>
        <div className="d-block mt-4">
          <input type="submit" value={confirmLabel} className="btn btn-primary btn-raised" />
        </div>
      </form>
    </ThemeProvider>
  );
};

export default InformAddress;

import { connect } from 'react-redux';
import action from 'alisto.js/lib/action';
import calculateFreightAndDispatch from '../lib/delivery/freight';
import AddressForm from './AddressForm';

const mapStateToProps = (state) => ({
  address: state.confirmingAddress,
  show: state.addressNeeded,
  searchedAddress: state.searchedAddress,
  isOutOfRange: state.isOutOfRange,
  canInputFreightPrice: state.canInputFreightPrice,
  enableDelivery: state.enableDelivery,
  enablePickup: state.allowPickups,
  deliveryMethod: state.deliveryMethod,
});

const mapDispatchToProps = (dispatch) => ({
  onHide: () => dispatch(action('CLOSE_ADDRESS_DIALOG')),
  onConfirmedAddress: (address, isSafe) => {
    if (isSafe && address.price) {
      dispatch(action('CONFIRM_GOOD_ADDRESS', address));
    } else {
      const { slug } = document.querySelector('[data-slug]').dataset;
      calculateFreightAndDispatch(slug, address, dispatch);
    }
  },
  onWrongAddress: () => dispatch(action('CONFIRM_WRONG_ADDRESS')),
  onResetAddress: () => dispatch(action('RESET_SEARCHED_ADDRESS')),
  onSearchAddress: (address) => dispatch(action('SEARCH_ADDRESS', address)),
  onSelectAddress: (address) => dispatch(action('SELECT_ADDRESS', address)),
  onChangeDeliveryMethod: (deliveryMethod) => dispatch(action('SET_DELIVERY_METHOD', deliveryMethod)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);

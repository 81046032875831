const { locale } = document.querySelector('html').dataset;

const texts = {
  br: {
    accept: 'Aceitar',
    activate: 'Ativar',
    add: 'Adicionar',
    addPayment: 'Adicionar pagamento',
    addProduct: 'Adicionar produto',
    addressInputModalContentDescription: 'Informe seu endereço para conhecer o tempo e a taxa de entrega.',
    allSelected: 'Todos selecionados',
    assign: 'Atribuir',
    billClosed: 'Conta fechada com sucesso',
    bills: 'Contas',
    birthdate: 'Data de nascimento',
    categories: 'Categorias',
    cancel: 'Cancelar',
    card: 'Comanda',
    cards: 'Comandas',
    change: 'Troco',
    changeFor: 'Troco para quanto',
    chooseOptionToContinue: 'Escolha uma opção para continuar',
    chooseTable: 'Escolher mesa',
    closeBill: 'Fechar conta',
    closeCashOperation: 'Fechar caixa',
    coin: 'Moeda',
    config: 'Configurações',
    confirmYourInfoToContinue: 'Confirme seus dados para continuar',
    consumeAtPlace: 'Consumir no local',
    continueOnTable: 'Continuar na mesa',
    cookieBanner: "Estamos em conformidade com a Lei Geral de Proteção de Dados (LGPD) e utilizamos cookies para oferecer uma melhor experiência. Ao clicar em 'Aceitar', você consente com a utilização de cookies.",
    createInvoice: 'Emitir NFC-e',
    createInvoiceHelpText: 'Opcionalmente especifique o CPF para colocar ele na nota.',
    daysToPrepare: 'dias de preparação',
    deliveryStatus: {
      closed: 'Delivery fechado',
      open: 'Delivery aberto',
    },
    deliveryTimeBetween: (min, max) => `Entrega de ${min} até ${max} minutos`,
    discount: 'Desconto',
    donation: 'Doação',
    doSignIn: 'Autentique-se',
    dontWannaSchedule: 'Não quero agendar',
    enableNotifications: 'Ative as notificações',
    enableNotificationsDescription: 'Receba atualizações sobre o seu pedido, promoções e outras novidades.',
    entries: 'Lançamentos',
    free: 'Livre',
    freight: 'Frete',
    gonnaConsumeAtPlace: 'Vou consumir o pedido no local',
    goToDelivery: 'Ir para o delivery',
    identification: 'Identificação',
    informAddress: 'Informar endereço',
    lastPositionAt: 'Última posição',
    maxWaitTimeUpdated: 'Tempo de entrega máximo atualizado.',
    missingPayment: 'Pagamento pendente',
    monitorCategories: 'Escolha as categorias que devem ficar visíveis no monitor',
    moveMapMarker: 'Arraste o marcador para confirmar a posição no mapa.',
    name: 'Nome',
    needsChange: 'Precisa de troco',
    neighborhood: 'Bairro',
    newCard: 'Abrir comanda',
    newCounterOrder: 'Venda direta',
    newOrder: 'Novo pedido',
    noPrinters: 'Para poder imprimir, primeiro configure as impressoras na aba Impressão das configurações da loja.',
    notPaidAndClosedBill: 'Conta fechada com pagamento pendente',
    observations: 'Observações',
    of: 'de',
    onlyScheduledOrdersForNow: 'Nesse momento somente recebemos pedidos agendados.',
    openCashOperation: 'Abrir caixa',
    openGrid: 'Abrir grid',
    orderCancelled: 'Pedido cancelado',
    orderCreated: 'Pedido criado com sucesso',
    openInvoice: 'Ver NFC-e',
    ordersTabAlreadyOpened: 'Essa tela foi aberta em outra aba. Deixe essa tela aberta unicamente em uma aba para evitar impressões duplicadas.',
    outOfStock: 'Indisponível',
    paid: 'Pago',
    paidWithSuccess: 'Pagamento realizado com sucesso',
    paidAndClosedBill: 'Conta paga e fechada',
    paidBill: 'Conta paga, mas não fechada',
    paymentChange: (amount, to) => `Troco de ${amount} para ${to}`,
    paymentDiscount: 'Desconto de pagamento',
    payments: 'Pagamentos',
    percentage: 'Percentagem',
    phone: 'Telefone',
    pickupIn: (min) => `Retirada em ${min} minutos`,
    promotionDiscount: 'Desconto de promoção',
    printed: 'Impressão enviada para a impressora',
    productTitle: 'Detalhes do produto',
    products: 'Produtos',
    productsUpdated: 'Produtos atualizados',
    readyIn: (min) => `Pronto em ${min} minutos`,
    remove: 'Remover',
    routes: {
      categories: 'categorias',
      products: 'produtos',
    },
    search: 'Pesquisar',
    searchAddress: 'Pesquisar endereço',
    seeLastPositions: 'Ver últimas posições',
    selectAll: 'Selecionar todos',
    selected: 'Selecionados',
    serviceFee: 'Taxa de serviço',
    serviceFeeHelperText: 'Deixe em branco para que a plataforma calcule o valor automáticamente',
    setAsDelivered: 'Marcar como entregue',
    setDocument: 'Especificar CPF',
    settedAsDelivered: 'Pedido marcado como entregue',
    start: 'Início',
    table: 'Mesa',
    theMostOrdered: 'O mais pedido',
    toSaveYourAddress: 'para salvar seu endereço e facilitar os próximos pedidos',
    transfered: 'Transferência realizada com sucesso',
    unavailable: 'Não disponível',
    update: 'Atualizar',
    attributes: {
      email: 'Email',
      password_confirmation: 'Confirmação de senha',
    },
    pdv: {
      chooseTableToTransfer: 'Escolha a mesa para a qual deseja transferir a conta',
      missingTables: 'Para utilizar o PDV é necessário primeiro cadastrar mesas com contas habilitadas',
      missingCards: 'Nenhuma comanda aberta, abra as comandas pela opção Novo pedido',
      ordersWaitingTodeliver: 'Pedidos aguardando serem entregues.',
      confirmations: {
        cancelOrder: 'Tem certeza que deseja cancelar o pedido?',
        transferBill: 'Tem certeza que deseja transferir a conta para a mesa TABLE?',
      },
    },
    pwa: {
      prompt: {
        ios: {
          body: 'Este site pode ser instalado como app para ser acessado diretamente a partir da tela de inicio.',
          home: 'Escolha a opção "Tela de inicio"',
          share: 'Aperte o ícone "Compartilhar"',
          title: 'Instale nosso Web App',
        },
      },
    },
    waitTime: 'Tempo de espera',
    waitTimeValue: (minutes) => `Até ${minutes} minutos`,
  },
  en: {
    accept: 'Accept',
    activate: 'Enable',
    add: 'Add',
    addPayment: 'Add payment',
    addProduct: 'Add product',
    addressInputModalContentDescription: 'Inform your address to know the wait time and the freight price.',
    allSelected: 'All selected',
    assign: 'Assign',
    billClosed: 'Bill closed successfully',
    bills: 'Bills',
    birthdate: 'Birthdate',
    categories: 'Categories',
    cancel: 'Cancel',
    card: 'Card',
    cards: 'Cards',
    change: 'Change',
    changeFor: 'Change for',
    chooseOptionToContinue: 'Choose an option to continue',
    chooseTable: 'Choose table',
    closeBill: 'Close bill',
    closeCashOperation: 'Close cash operation',
    coin: 'Moin',
    config: 'Configurations',
    confirmYourInfoToContinue: 'Confirm your data to continue',
    consumeAtPlace: 'Consume at place',
    continueOnTable: 'Continue in the table',
    cookieBanner: "We comply with the General Data Protection Regulation (GDPR) and use cookies to provide you with a better experience. By clicking 'Accept', you consent to the use of cookies.",
    createInvoice: 'Create invoice',
    createInvoiceHelpText: '',
    daysToPrepare: 'days to prepare',
    deliveryStatus: {
      closed: 'Delivery closed',
      open: 'Delivery open',
    },
    deliveryTimeBetween: (min, max) => `Delivery from ${min} to ${max} minutes`,
    discount: 'Discount',
    donation: 'Donation',
    doSignIn: 'Sign-in',
    dontWannaSchedule: "I don't want to schedule",
    enableNotifications: 'Enable notifications',
    enableNotificationsDescription: 'Get updates about your order, promotions and other news.',
    entries: 'Entries',
    free: 'Free',
    freight: 'Freight',
    gonnaConsumeAtPlace: 'I want to consume the order at place',
    goToDelivery: 'Go to delivery',
    lastPositionAt: 'Last position',
    maxWaitTimeUpdated: 'Max wait time updated',
    missingPayment: 'Payment is missing',
    moveMapMarker: 'Move the marker to set the position on the map',
    monitorCategories: 'Choose the categories that should remain visible in the monitor',
    name: 'Name',
    needsChange: 'Needs change',
    neighborhood: 'Neighborhood',
    newCard: 'New card',
    newCounterOrder: 'Direct sale',
    newOrder: 'New order',
    noPrinters: 'To print, first configure the prints in the tab Printing of the store configuration',
    notPaidAndClosedBill: 'Closed bill with payment missing',
    observations: 'Observations',
    of: 'of',
    onlyScheduledOrdersForNow: "At this time we're only receiving scheduled orders.",
    openCashOperation: 'Open cash operation',
    openGrid: 'Open grid',
    orderCancelled: 'Order cancelled',
    orderCreated: 'Order created successfully',
    openInvoice: 'View invoice',
    ordersTabAlreadyOpened: 'This screen is already opened in another tab. Keep this screen opened in one tab only to avoid duplicated prints.',
    outOfStock: 'Out of stock',
    paid: 'Paid',
    paidWithSuccess: 'Payment done successfully',
    paidAndClosedBill: 'Paid and closed bill',
    paidBill: 'Bill paid, but not closed',
    paymentChange: (amount, to) => `Change of ${amount} for ${to}`,
    paymentDiscount: 'Payment discount',
    payments: 'Payments',
    percentage: 'Percentage',
    phone: 'Phone',
    pickupIn: (min) => `Pickup in ${min} minutes`,
    promotionDiscount: 'Promotion discount',
    printed: 'Print send to the printer',
    productTitle: 'Product details',
    products: 'Products',
    productsUpdated: 'Products updated',
    readyIn: (min) => `Ready in ${min} minutes`,
    remove: 'Remove',
    routes: {
      categories: 'categories',
      products: 'products',
    },
    search: 'Search',
    searchAddress: 'Search address',
    seeLastPositions: 'See last positions',
    selectAll: 'Select all',
    selected: 'Selecionados',
    serviceFee: 'Service fee',
    serviceFeeHelperText: 'Leave the field blank for the platform to calculate the value automatically',
    setAsDelivered: 'Update as delivered',
    setDocument: 'Specify document',
    settedAsDelivered: 'Order updated as delivered',
    start: 'Start',
    table: 'Table',
    theMostOrdered: 'The most ordered',
    toSaveYourAddress: 'to save your address and use it in the next orders',
    transfered: 'Transfer done successfully',
    unavailable: 'Unavaiable',
    update: 'Update',
    attributes: {
      email: 'Email',
      password_confirmation: 'Password confirmation',
    },
    pdv: {
      chooseTableToTransfer: 'Choose a table to transfer the bill',
      missingTables: 'Tables with enabled bills should be created in order to use the PDV',
      missingCards: 'No cards opened, open the cards with the option New order',
      ordersWaitingTodeliver: 'Orders waiting to be delivered.',
      confirmations: {
        cancelOrder: 'Are you sure you want to cancel the order?',
        transferBill: 'Are you sure you want to transfer the bill to the table TABLE?',
      },
    },
    pwa: {
      prompt: {
        ios: {
          body: 'This site can be installed as an app to be accessed directly from the home screen.',
          home: 'Press "Add to Home Screen"',
          share: 'Press the "Share" button',
          title: 'Install our webapp',
        },
      },
    },
    waitTime: 'Wait time',
    waitTimeValue: (minutes) => `Up to ${minutes} minutes`,
  },
  pe: {
    accept: 'Aceptar',
    activate: 'Activar',
    add: 'Adicionar',
    addPayment: 'Adicionar pago',
    addProduct: 'Adicionar producto',
    addressInputModalContentDescription: 'Informe su dirección para conocer el tiempo y la tasa de entrega.',
    allSelected: 'Todos seleccionados',
    assign: 'Atribuyir',
    billClosed: 'Cuenta cerrada com suceso',
    bills: 'Cuentas',
    birthdate: 'Fecha de nacimiento',
    categories: 'Categorias',
    cancel: 'Cancelar',
    card: 'Tarjeta',
    cards: 'Tarjetas',
    change: 'Vuelto',
    changeFor: 'Troco para cuanto',
    chooseOptionToContinue: 'Elija una opción para continuar',
    chooseTable: 'Escojer mesa',
    closeBill: 'Cerrar cuenta',
    closeCashOperation: 'Cerra caja',
    coin: 'Moneda',
    config: 'Configuraciones',
    confirmYourInfoToContinue: 'Confirme seus datos para continuar',
    consumeAtPlace: 'Consumir en el local',
    continueOnTable: 'Continuar en la mesa',
    cookieBanner: 'Usamos cookies',
    createInvoice: 'Emitir factura',
    createInvoiceHelpText: '',
    daysToPrepare: 'dias de preparación',
    deliveryStatus: {
      closed: 'Delivery cerrado',
      open: 'Delivery abierto',
    },
    deliveryTimeBetween: (min, max) => `Entrega de ${min} hasta ${max} minutos`,
    discount: 'Descuento',
    donation: 'Donación',
    doSignIn: 'Autentique-se',
    dontWannaSchedule: 'No quiero agendar',
    enableNotifications: 'Active las notificaciones',
    enableNotificationsDescription: 'Reciba actualizaciones sobre su pedido, promociones y otras novedades.',
    entries: 'Lanzamientos',
    free: 'Libre',
    freight: 'Flete',
    gonnaConsumeAtPlace: 'Vou consumir o pedido en el local',
    goToDelivery: 'Ir para el delivery',
    identification: 'Identificación',
    informAddress: 'Informar dirección',
    lastPositionAt: 'Última posición',
    maxWaitTimeUpdated: 'Tiempo de entrega máximo actualizado.',
    missingPayment: 'Pago pendiente',
    monitorCategories: 'Escoja las categorias que deben quedarse visibles en el monitor',
    moveMapMarker: 'Arraste el marcador para ajustar la posición en el mapa',
    name: 'Nombre',
    needsChange: 'Necesita de vuelto',
    neighborhood: 'Barrio',
    newCard: 'Abrir tarjeta',
    newCounterOrder: 'Venta directa',
    newOrder: 'Nuevo pedido',
    noPrinters: 'Para poder imprimir, primeiro configure las impressoras en la aba Impresión de las configuraciones de la tienda.',
    notPaidAndClosedBill: 'Cuenta cerrada con pago pendiente',
    observations: 'Observaciones',
    of: 'de',
    onlyScheduledOrdersForNow: 'En este momento solamente recebemos pedidos agendados.',
    openCashOperation: 'Abrir caja',
    openGrid: 'Abrir grid',
    orderCancelled: 'Pedido cancelado',
    orderCreated: 'Pedido creado con suceso',
    openInvoice: 'Abrir factura',
    ordersTabAlreadyOpened: 'Esta página fue abierta en otra aba. Deje esta página abierta solamente en una aba para evitar impresiones duplicadas.',
    outOfStock: 'Indisponible',
    paid: 'Pago',
    paidWithSuccess: 'Pago realizado con suceso.',
    paidAndClosedBill: 'Cuenta pagada y cerrada',
    paidBill: 'Cuenta paga, pero no cerrada',
    paymentChange: (amount, to) => `Vuelto de ${amount} para ${to}`,
    paymentDiscount: 'Descuento de pago',
    payments: 'Pagos',
    percentage: 'Porcentual',
    phone: 'Teléfono',
    pickupIn: (min) => `Retirada en ${min} minutos`,
    promotionDiscount: 'Descuento de promoción',
    printed: 'Impresión enviada para la impresora',
    productTitle: 'Detalles del producto',
    products: 'Productos',
    productsUpdated: 'Productos actualizados',
    readyIn: (min) => `Listo en ${min} minutos`,
    remove: 'Remover',
    routes: {
      categories: 'categorias',
      products: 'productos',
    },
    search: 'Buscar',
    searchAddress: 'Buscar dirección',
    seeLastPositions: 'Ver últimas posiciones',
    selectAll: 'Seleccionar todos',
    selected: 'Selecionados',
    serviceFee: 'Tasa de servicio',
    serviceFeeHelperText: 'Deje en blanco para que la plataforma calcule el valor automaticamente',
    setAsDelivered: 'Marcar como entregado',
    setDocument: 'Especificar RUC',
    settedAsDelivered: 'Pedido marcado como entregado',
    start: 'Inicio',
    table: 'Mesa',
    theMostOrdered: 'El más pedido',
    toSaveYourAddress: 'para salvar su dirección y facilitar los próximos pedidos',
    transfered: 'Transferencia realizada com suceso',
    unavailable: 'No disponible',
    update: 'Actualizar',
    attributes: {
      email: 'Email',
      password_confirmation: 'Confirmación de clave',
    },
    pdv: {
      chooseTableToTransfer: 'Escoja la mesa para la cual desea transferir la cuenta',
      missingTables: 'Para utilizar el PDV es necesario primero registrar mesas con cuentas habilitadas',
      missingCards: 'Ninguna tarjeta abierta, abra las comandas por la opción Nuevo Pedido',
      ordersWaitingTodeliver: 'Pedidos esperando ser entregados.',
      confirmations: {
        cancelOrder: 'Está seguro que quiere cancelar el pedido?',
        transferBill: 'Está seguro que quiere transferir la cuenta para la mesa TABLE?',
      },
    },
    pwa: {
      prompt: {
        ios: {
          body: 'Este sitio web se puede instalar como una aplicación a la que se accede directamente desde la pantalla de inicio.',
          home: 'Elija la opción "Pantalla de inicio"',
          share: 'Presiona el ícono "Compartir"',
          title: 'Instale nuestra Web App',
        },
      },
    },
    waitTime: 'Tiempo de espera',
    waitTimeValue: (minutes) => `Hasta ${minutes} minutos`,
  },
};

texts.es = {
  ...texts.pe,
  setDocument: 'Especificar CIF',
};

texts.py = texts.pe;

texts.pt = {
  ...texts.br,
  neighborhood: 'Freguesia',
};

texts.uk = texts.en;

export default texts[locale];

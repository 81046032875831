import React from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import toCurrency from 'alisto.js/lib/to-currency';
import action from 'alisto.js/lib/action';
import i18n from '../../../../javascript/js/i18n';

const Header = function ({ menu, dispatch }) {
  const address = useSelector((state) => state.confirmedAddress);

  const showSchedule = (event) => {
    event.preventDefault();
    window.showSchedule();
  };

  return (
    <Paper className="p-3">
      <div id="establishment-logo">
        <picture>
          <source srcSet={menu.logo.large} media="(min-width: 576px)" />
          <img
            src={menu.logo.small}
            className={`img-fluid ${menu.logoFormat === 'rounded' ? 'rounded-circle' : 'rounded'}`}
            alt="Logo"
          />
        </picture>
      </div>
      <div className="float-right" id="open-feedback">
        <button
          type="button"
          onClick={showSchedule}
          className={`show-schedule border-0 bg-white ${menu.available ? 'text-success' : 'text-danger'}`}
        >
          <i className="fas fa-store mr-1" />
          {menu.statusLabel}
        </button>
      </div>
      <h1 className="my-2" style={{ fontSize: `${menu.titleFontSize}rem` }}>{menu.name}</h1>
      <div className="w-100 overflow-x-auto">
        <div className="d-flex justify-content-start">
          {menu.table ? (
            <div className="mr-4 pr-lg-4">
              <strong className="text-nowrap">{menu.texts.deliverToTable}</strong>
            </div>
          ) : (
            <>
              {!menu.readonly && (
                <>
                  <div className="mr-4 pr-lg-4">
                    <div className="text-nowrap">
                      <small>
                        <i className="fas fa-motorcycle mr-1" />
                      </small>
                      <span className="text-nowrap">
                        {menu.enableDelivery ? menu.texts.freightPrice : 'Método de entrega'}
                      </span>
                    </div>
                    <span className="text-nowrap">
                      {menu.enableDelivery ? (
                        <button
                          type="button"
                          onClick={() => dispatch(action('EDIT_ADDRESS'))}
                          className="show-schedule border-0 bg-white text-primary"
                        >
                          {address?.price !== undefined ? toCurrency(address.price) : menu.freightPrice}
                        </button>
                      ) : menu.texts.pickupOnly}
                    </span>
                  </div>

                  {menu.open && (
                    <div className="mr-4 pr-lg-4">
                      <div className="text-nowrap">
                        <small>
                          <i className="fas fa-clock align-middle mr-1" style={{ marginTop: '-4px' }} />
                        </small>
                        <span className="text-nowrap">{menu.texts.waitTime}</span>
                      </div>
                      <span className="text-nowrap">
                        {address?.maxWait ? i18n.waitTimeValue(address.maxWait) : menu.waitTime}
                      </span>
                    </div>
                  )}
                </>
              )}

              {menu.phone && (
                <div className="mr-4 pr-lg-4">
                  <div className="text-nowrap">
                    <small>
                      <i className="fas fa-phone align-middle mr-1" style={{ marginTop: '-4px' }} />
                    </small>
                    <span className="text-nowrap">{menu.texts.phone}</span>
                  </div>
                  <span className="text-nowrap">
                    <Link href={`tel:${menu.phone.replace(/\D/g, '')}`}>
                      {menu.phone}
                    </Link>
                  </span>
                </div>
              )}

              {menu.minOrder > 0 && !menu.readonly && (
                <div className="mr-4 pr-lg-4">
                  <div className="text-nowrap">
                    <small>
                      <i className="fas fa-dollar-sign align-middle mr-1" style={{ marginTop: '-4px' }} />
                    </small>
                    <span className="text-nowrap">{menu.texts.minOrder}</span>
                  </div>
                  <span className="text-nowrap">{menu.minOrderWithCurrency}</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default React.memo(Header);

import React from 'react';
import Button from '@material-ui/core/Button';

const AddressInput = function ({ address, onEdit, editLabel }) {
  return (
    address ? (
      <>
        <Button
          onClick={onEdit}
          color="secondary"
          className="p-0 m-0"
          style={{ minWidth: 'auto' }}
        >
          <i className="fas fa-fw fa-pen edit-address mr-2 align-self-center" style={{ fontSize: '1rem' }} />
        </Button>
        <div>
          <div className="d-flex flex-wrap">
            <span className="mr-1">
              {address.street}
              {' '}
              {address.number}
              ,
            </span>
            {address.complement && address.complement.length > 0 ? (
              <span className="mr-1 text-nowrap">
                {address.complement}
                ,
              </span>
            ) : ''}
            <span className="text-nowrap mr-1">
              {address.neighborhood}
              {address.city ? ',' : ''}
            </span>
            {address.city && (
              <span className="text-nowrap mr-1">
                {address.city}
                {' '}
                {address.state && (
                  <>
                    -
                    {address.state}
                  </>
                )}
              </span>
            )}
            <span className="text-nowrap">{address.zipcode}</span>
          </div>
          <small className="d-block">{address.reference}</small>
        </div>
      </>
    ) : (
      <button type="button" onClick={onEdit} className="btn btn-link btn-primary p-0 m-0">
        <i className="fas fa-fw add-address fa-map-marker-alt mr-2 align-self-center" />
        {editLabel}
      </button>
    )
  );
};

export default AddressInput;
